import React from "react";

export const postRoutes = [
  {
    exact: true,
    key: "project",
    path: `/post-list`,
    component: React.lazy(() => import("../../pages/post-list/index.js")),
  },

  {
    exact: true,
    key: "project",
    path: `/post-detail/:slug`,
    component: React.lazy(() => import("../../pages/post-detail/index.js")),
  },

  {
    exact: true,
    key: "invest",
    path: `/invest`,
    component: React.lazy(() => import("../../pages/invest/index.js")),
  },
];
