import { options } from "../../../../helpers/options/options";

export const SiderContents = [
  {
    key: "dashboard",
    title: "Tổng quan",
    permissions: [],
    path: "/",
  },

  {
    key: "project",
    title: "Dự án",
    permissions: [],
    path: `/post-list`,
  },

  {
    key: "invest",
    title: "Đầu tư",
    permissions: [],
    path: `/invest`,
  },

  {
    key: "planning-request",
    title: "Kiểm tra quy hoạch",
    permissions: [],
    path: `/planning-request-list`,
  },

  {
    key: "planning-information",
    title: "Thông tin quy hoạch",
    permissions: [],
    path: `/planning-information-list`,
  },

  {
    key: "user-list",
    title: "Khách hàng",
    permissions: [],
    path: `/user-list`,
  },

  {
    key: "payment-list",
    title: "Lịch sử thanh toán",
    permissions: [],
    path: `/payment-list`,
  },

  {
    key: "new-list",
    title: "Tin tức",
    permissions: [],
    path: `/new-list`,
  },

  // {
  //   key: "procedures",
  //   title: "Thủ tục mua bán",
  //   permissions: [],
  //   path: `/${options.routeGroupPaths.riverlandPathName}/proceduresList`,
  // },

  // {
  //   key: "news",
  //   title: "Tin tức",
  //   permissions: [],
  //   path: `/${options.routeGroupPaths.riverlandPathName}/newsList`,
  // },
];
